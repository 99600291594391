<template>
  <div class="row">
    <div class="col-md-12 card">
      <div class="card-body row">
        <div class="col-sm-6">
          <div class="card-header">
        <h5 class="card-title">Productions</h5>
      </div>
      </div>
        <div class="col-sm-6">
          <div class="pull-right">
            <div class="btn-group">
              <p-button type="primary" round outline @click="handleNew()">New</p-button>
              <p-button type="primary" round outline @click="resetTable()">Reset</p-button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body row">
        <div class="col-sm-12 mt-2">
          <div>
            <vue-good-table
              :theme="tableTheme"

              ref="productionsGrid"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"

              :columns="columns"
              :rows="rows"
              :pagination-options="{
                enabled: true,
                mode: 'records',

                perPage: serverParams.perPage,
                setCurrentPage: serverParams.page,

                position: 'bottom',
                perPageDropdown: [10, 30, 50],
                dropdownAllowAll: true,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page', // for 'pages' mode
                allLabel: 'All',
                }"
              :sort-options="{
                enabled: true,
                initialSortBy: serverParams.sort
                }">
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'actions'">
                    <p-button type="info" size="sm" v-if="props.row.userId" icon @click="handleRemove(props.$index, props.row)">
                      <i class="fa fa-heart"></i>
                    </p-button>
                    <p-button type="info" size="sm" v-else icon @click="handleAdd(props.$index, props.row)">
                      <i class="fa fa-heart-o"></i>
                    </p-button>
                    <p-button type="success" size="sm" icon @click="handleEdit(props.$index, props.row)">
                      <i class="fa fa-edit"></i>
                    </p-button>
                  </span>
                  <span v-else>
                    {{props.formattedRow[props.column.field]}}
                  </span>
                </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import * as CONSTS from '../../../consts/constants'

  export default{
    components: {

    },
    computed: {
      ...mapGetters(['currentUser', 'gridStates', 'tableTheme'])
    },
    mounted () {
      this.updateTableFilters()
    },
    created () {

      let gridState = this.gridStates[this.gridName]

      if (gridState){
        this.serverParams = gridState
      }

      this.loadData()
    },

    data () {
      return {
        gridName: 'productionsGrid',
        serverParams: {
          gridName: 'productionsGrid',
          columnFilters: {
          },
          sort: {
            field: 'productionName', type: 'asc'
          },
          page: 1,
          perPage: 10
        },
        columns: [
          {
            label: 'Actions',
            field: 'actions',
          },
          {
            field: 'productionName',
            label: 'Name',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'productionCode',
            label: 'Code',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'production_forms_close',
            label: 'Forms Close',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            formatFn: this.formatDate,
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },

          {
            field: 'nominalCode',
            label: 'Nominal Code',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
          {
            field: 'projectCode',
            label: 'Project Code',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            type: 'number',
            filterOptions: {
              enabled: true,
              placeholder: 'Filter'
            }
          },
           {
            field: 'userSelected',
            label: 'Selected',
            tdClass: 'tablecell',
            thClass: 'tablecell',
            type: 'number',
            formatFn: this.formatSelected,
            filterOptions: {
              enabled: true,
              filterDropdownItems: CONSTS.strings.no_yes
            }
          }
        ],
        rows: []
      }
    },
    methods: {
      //
      updateTableFilters() {

        for (let i = 0; i < this.columns.length; i++) {
          const col = this.columns[i];
          if (typeof col.filterOptions !== 'undefined') {

            let filterVal = {}

            if ( (filterVal = this.serverParams.columnFilters[ this.columns[i].field ] ) ) {

              let filter = this.columns[i].filterOptions
              filter.filterValue = filterVal
              // seems bonkers having to do this
              let  filterString = JSON.stringify(filter)
              let filterObj = JSON.parse(filterString)

              this.$set(this.columns[i], 'filterOptions', filterObj )
            }
          }
        }
      },
      resetTable: function() {
        this.$refs['productionsGrid'].reset()
        this.serverParams.columnFilters = []
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // table events
      onPageChange(params) {
        this.serverParams.page = params.currentPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onPerPageChange(params) {
        this.serverParams.perPage = params.currentPerPage
        this.$store.dispatch('setGridState', this.serverParams )
      },

      onSortChange(params) {
        if (params.columnIndex){
        
          let newSort = {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          }

          this.serverParams.sort = newSort
          this.$store.dispatch('setGridState', this.serverParams )
        }
      },

      onColumnFilter(params) {
        this.serverParams.columnFilters = params.columnFilters
        this.$store.dispatch('setGridState', this.serverParams )
      },

      // end table events
       searchArray: function(array, key, prop){
          // Optional, but fallback to key['name'] if not selected
          prop = (typeof prop === 'undefined') ? 'name' : prop;

          for (var i=0; i < array.length; i++) {
              if (array[i][prop] === key) {
                  return array[i];
              }
          }
          return null
      },
      loadData: function () {


        this.$http.get( 'common/production/user/' + this.currentUser.userId )
        .then(response => {
          this.rows = response.data

          for (let v = 0; v < this.rows.length; v++){

            if ( this.rows[v].userId ){
              this.rows[v].userSelected = 1
            } else {
              this.rows[v].userSelected = 0
            }
          }
        })
        .catch(error => {
          // console.log(error)
          this.$notify({
            message: 'There was a problem loading the productions. Please try again.',
            type: 'warning'
          })
        })
      },

      formatDate (value) {
        return this.$moment(value).format('DD-MM-YYYY')
      },

      formatSelected(value) {

        let obj = this.searchArray(CONSTS.strings.no_yes, value, 'value')
          if (obj) {
            return obj.text
          }
      },
      handleRemove (index, row) {

        var that = this;
        let indexToRemove = this.rows.findIndex((tableRow) => tableRow.productionId === row.productionId)

        if (row.recordId !== null){

          this.$http.delete('common/user/production/' + row.recordId)
            .then(response => {

              if  (response.data.apiresponse.status === "success"){

                  this.$notify({
                    message: row.productionName + ' was removed from your productions',
                    type: 'info'
                  })

                  // clear the flag in the table data
                  that.rows[indexToRemove].userId = null
                  that.rows[indexToRemove].userSelected = 0
                  // remove from the store
                  that.$store.dispatch('removeUserProduction', row)

              } else {
                  this.$notify({
                  message: 'Sorry a problem occurred removing ' + row.productionName + ' from your productions',
                  type: 'warning'
                })
              }
            })
            .catch(response => {
                this.$notify({
                  message: 'Sorry a problem occurred removing ' + row.productionName + ' from your productions',
                  type: 'warning'
                })
            });
        } else {

            console.log ('remove my production with null record id')
            this.$notify({
                  message: 'Sorry a problem occurred removing ' + row.productionName + ' from your productions',
                  type: 'warning'
                })
        }
      },
      handleAdd (index, row) {

        var that = this;
        let indexToAdd = this.rows.findIndex((tableRow) => tableRow.productionId === row.productionId)

        this.$http.post('common/user/production', {
                userId: this.currentUser.userId,
                productionId: row.productionId
            }, {})
            .then(response => {

              if  (response.data.apiresponse.status === "success"){

                  this.$notify({
                    message: row.productionName + ' added to your productions',
                    type: 'info'
                  })

                  // update the table date
                  that.rows[indexToAdd].userId = that.currentUser.userId
                  that.rows[indexToAdd].recordId = response.data.apiresponse.keys[0]

                  // add to the store
                  console.log(row)
                  that.$store.dispatch('addUserProduction', row)

              } else {
                  this.$notify({
                  message: 'Sorry a problem occurred adding ' + row.productionName + ' to your productions',
                  type: 'warning'
                })
              }

            })
            .catch(response => {
                this.$notify({
                  message: 'Sorry a problem occurred adding ' + row.productionName + ' to your productions',
                  type: 'warning'
                })
            })
      },
      handleEdit (index, row) {
        this.$router.push({ name: 'Production', params: { id: row.productionId }})
      }
      ,
      handleNew () {
        this.$router.push({ name: 'Production'})
      }
    }

  }
</script>

<style lang="scss">
  .tablecell {
   font-size: 14px;
 }
</style>
